export const data = [
  {
    to: "/",
    text: "Ürünler",
    id: "product",
  },
  {
    to: "/",
    text: "Hakkında",
    id: "about",
  },
  {
    to: "/contact",
    text: "İletişim",
  },
];
