import React, { Suspense, lazy } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Launch from "./components/Launch";
import Navbar from "./components/Navbar/Navbar";
import GlobalStyle from "./globalStyles";

const Home = lazy(() => import("./pages/Home"));
const SignUp = lazy(() => import("./pages/SignupPage"));
const Pricing = lazy(() => import("./pages/PricingPage"));
const Footer = lazy(() => import("./components/Footer/Footer"));
const ProductDetail = lazy(() => import("./pages/ProductDetail"));
const RoomProductDetail = lazy(() => import("./pages/RoomProductDetail"));
const PreFabricProductDetail = lazy(() => import("./pages/PreFabricProductDetail"));
const ProductDownloader = lazy(() => import("./pages/ProductDownloader"));

function App() {
  return (
    <Suspense fallback={<Launch />}>
      <Router>
        <GlobalStyle />
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/contact" exact component={SignUp} />
          <Route path="/pricing" exact component={Pricing} />
          <Route path="/product-detail-cooling-system" exact component={ProductDetail} />
          <Route path="/product-detail-room-system" exact component={RoomProductDetail} />
          <Route path="/product-detail-pre-fabric" exact component={PreFabricProductDetail} />
          <Route path="/product-download" exact component={ProductDownloader} />
        </Switch>
        <Footer />
      </Router>
    </Suspense>
  );
}

export default App;
