import React from "react";
import "./index.css";

const Launch = () => {
    return (
        <div className="container">
            <h3>Yükleniyor...</h3>
        </div>
    );
};

export default Launch;
